const __o365_sfc__ = {};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "icon-wrapper"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("svg", {
    class: "ov-icon",
    "aria-hidden": "true",
    width: "32",
    height: "32",
    viewBox: "0.48 0.48 23.04 23.04",
    fill: "#fff"
  }, [_createElementVNode("path", {
    d: "M14.71 15.88L10.83 12l3.88-3.88a.996.996 0 10-1.41-1.41L8.71 11.3a.996.996 0 000 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z"
  })], -1 /* HOISTED */)]));
}
    __o365_sfc__.name = 'LeftArrow';
    __o365_sfc__.__file = 'LeftArrow';
    try {
__o365_sfc__.render = render;
} catch (ex) {
    window['console'].warn(ex);
}
    __o365_sfc__.__scopeId = 'data-v-10fbee4e-23cc-4f38-9d1b-f475017eabb4';
    export default __o365_sfc__;
            let style = document.createElement('style');
            style.setAttribute('type', 'text/css');
            style.innerHTML = `
.icon-wrapper[data-v-10fbee4e-23cc-4f38-9d1b-f475017eabb4] {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

`;
            document.head.appendChild(style);
            